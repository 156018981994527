/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Split = ({ text }) => {
	const sp = text.split("OR")
	return (
		<>
			{sp.map((s, i) => {
				const isLast = sp.length - i === 1
				return isLast ? (
					s
				) : (
					<>
						<span className="flex pr-8">
							<span className="flex-grow">{s} </span>
							OR
						</span>
					</>
				)
			})}
		</>
	)
}

const Two = ({ a, b }) => (
	<div className="flex items-end">
		<span className="flex-grow">
			<Split text={a} />
		</span>
		<span className="flex-grow-0">{b}</span>
	</div>
)

const Domain = ({ title, criteria }) => (
	<div className="p-2 border-b">
		<span className="font-semibold text-purple-600">{title}</span>
		{criteria.map(({ name, weight }) => (
			<Two a={name} b={weight} />
		))}
	</div>
)

const NewestCriteria = () => (
	<Layout>
		<SEO title="Newest criteria for lupus diagnosis" />
		<h1>Newest criteria for lupus diagnosis</h1>
		<p>
			The European league against Rheumatism and the American College of
			Rheumatology have recently revised the classification criteria for a
			possible lupus diagnosis by introducing a symptom based weighting/scoring
			system.
		</p>
		<p>
			We have incorporated this criteria into{" "}
			<Link
				className="link"
				to="/lupus-diagnostic-guidelines/quiz-do-i-have-lupus"
			>
				an interactive quiz
			</Link>{" "}
			that you can use to determine whether you may have a diagnosis of lupus.
		</p>
		<p>The criteria consists of lab tests in addition to clinical symptoms.</p>
		<h2>1. Entry criterion</h2>
		<p>
			This is the initial lab test - the ANA (antinuclear antibodies) test. You
			cannot get a clinical lupus diagnosis without this test being done and
			returning a positive reading.
		</p>
		<h2>2. Additive criteria</h2>
		<p>
			In addition to a positive ANA test, there are different domains with
			clinical symptoms and/or lab tests within each domain, that are taken into
			consideration. Each symptom and test has a &apos;weight&apos; assigned to
			it and you must have a weight score of 10 or higher to be diagnosed with
			lupus.
		</p>
		<p>Other points of note:</p>
		<ul>
			<li>
				Do not count any of the criteria if there is a more likely explanation
				than lupus for it.
			</li>
			<li>
				Any of the criteria need to have occurred only once for it to be
				counted.
			</li>
			<li>
				The different domains are split into clinical and immunology domains -
				at least one clinical criteria needs to be present.
			</li>
			<li>The criteria do not need to have occurred simultaneously.</li>
			<li>
				If there are multiple criteria present/positive within a single domain,
				ONLY the one with the highest weight is counted toward the total score.
			</li>
			<li>A score of 10 or higher is indicative of lupus.</li>
		</ul>

		<div className="grid gap-3 grid-cols-1 md:grid-cols-2 md:shadow-md p-2">
			<div className="shadow-md p-2 md:shadow-none md:p-0">
				<h3>
					<Two a="Clinical domains and criteria" b="Weight" />
				</h3>

				<Domain
					title="Constitutional"
					criteria={[{ name: "Fever", weight: "2" }]}
				/>
				<Domain
					title="Hematologic"
					criteria={[
						{ name: "Leukopenia", weight: "3" },
						{
							name: "Thrombocytopenia",
							weight: "4",
						},
						{
							name: "Autoimmune hemolysis",
							weight: "4",
						},
					]}
				/>
				<Domain
					title="Neuropsychiatric"
					criteria={[
						{
							name: "Delirium",
							weight: "2",
						},
						{
							name: "Psychosis",
							weight: "3",
						},
						{ name: "Seizure", weight: "5" },
					]}
				/>
				<Domain
					title="Mucocutaneous"
					criteria={[
						{
							name: "Non-scarring alopecia",
							weight: "2",
						},
						{
							name: "Oral ulcers",
							weight: "2",
						},
						{
							name: "Subacute cutaneous or discoid lupus",
							weight: "4",
						},
						{
							name: "Acute cutaneous lupus",
							weight: "6",
						},
					]}
				/>
				<Domain
					title="Serosal"
					criteria={[
						{
							name: "Pleural or pericardial effusion",
							weight: "5",
						},
						{
							name: "Acute pericarditis",
							weight: "6",
						},
					]}
				/>
				<Domain
					title="Musculoskeletal"
					criteria={[
						{
							name: "Joint involvement",
							weight: "6",
						},
					]}
				/>
				<Domain
					title="Renal"
					criteria={[
						{
							name: "Proteinuria >0.5g/24 hours",
							weight: "4",
						},
						{
							name: "Renal biopsy class II or V lupus nephritis",
							weight: "8",
						},
						{
							name: "Renal biopsy class III or IV lupus nephritis",
							weight: "10",
						},
					]}
				/>
			</div>
			<div className="shadow-md p-2 md:shadow-none md:p-0">
				<h3>
					<Two a="Immunology domains and criteria" b="Weight" />
				</h3>
				<Domain
					title="Antiphospholipid antibodies"
					criteria={[
						{
							name:
								"Anti-cardiolipin antibodies OR Anti-β2GP1 antibodies OR Lupus anticoagulant",
							weight: "2",
						},
					]}
				/>
				<Domain
					title="Complement proteins"
					criteria={[
						{
							name: "Low C3 or low C4",
							weight: "3",
						},
						{
							name: "Low C3 and low C4",
							weight: "4",
						},
					]}
				/>
				<Domain
					title="SLE-specific antibodies"
					criteria={[
						{
							name: "Anti-dsDNA antibody OR Anti-Smith antibody",
							weight: "6",
						},
					]}
				/>
			</div>
		</div>
		<Link to="/lupus-diagnostic-guidelines/quiz-do-i-have-lupus">
			<button type="button" className="btn">
				Go to the interactive quiz
			</button>
		</Link>
	</Layout>
)

export default NewestCriteria
